import React, { useReducer, useState } from "react";

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useNavigate } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CForm, CCol } from '@coreui/react';
import { CFormInput } from '@coreui/react';
import { CButton } from '@coreui/react';
import { formatCPF } from '../../helpers/format';
import api from "../../hooks/api";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const tiers = [
  {
    title: 'Anual - 02 Treinos',
    price: '60',
    description: ['2 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'anual-2',
  },
  {
    title: 'Anual - 03 Treinos',
    // subheader: 'Indicado',
    price: '70',
    description: [
      '3 Treinos semanais',
      'Material não incluso',
    ],
    buttonText: 'Assinar',
    buttonVariant: 'contained',
    plano: 'anual-3',
  },
  {
    title: 'Mensal - 02 Treinos',
    price: '80',
    description: ['2 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'mensal-2',
  },
  {
    title: 'Mensal - 03 Treinos',
    price: '95',
    description: ['3 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'mensal-3',
  },
  {
    title: 'Trimestral - 02 Treinos',
    price: '70',
    description: ['2 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'trimestral-2',
  },
  {
    title: 'Trimestral - 03 Treinos',
    price: '80',
    description: ['3 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'trimestral-3',
  },
  {
    title: 'Semestral - 02 Treinos',
    price: '65',
    description: ['2 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'semestral-2',
  },
  {
    title: 'Semestral - 03 Treinos',
    price: '75',
    description: ['3 Treinos semanais', 'Material não incluso'],
    buttonText: 'Assinar',
    buttonVariant: 'outlined',
    plano: 'semestral-3',
  },
];

export default function Home(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const nextStep = (plano) => {
    navigate(`cadastro`, { state: { plano: plano } });
  }
  const [formData, setFormData] = useReducer(formReducer, {});
  const [isCPFValid, setValidCPF] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [aluno, setAluno] = useState(false)

  const handleChange = (event, value) => {
    setFormData({
      name: event.name || event.label || event.target.name,
      value: value,
    });

  };

  const validateCPF = (value) => {
    if (formatCPF(value)?.length > 0) setValidCPF(true)
    if (formatCPF(value)?.length === 0) setValidCPF(false)

    return value
  }

  const callSearch = async (e) => {
    e.preventDefault();
    setSubmit(true)
    await api.get(`/`).then(rs => {
      setAluno(rs.data)
      navigate(`busca`, { state: { aluno: rs.data } });
    }).catch(error => console.log(error));
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button href="login" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
          Gadelha Team
        </Typography>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
          <CForm className="row g-3">
            <CCol md={12}>
              <CFormInput onChange={e => handleChange(e, formatCPF(validateCPF(e.target.value)))} feedback="Digite um CPF válido." name="cpf" value={formatCPF(formData.cpf)} type="text" id="inputCpf" placeholder="___.___.___-__" invalid={!isCPFValid && submit} label="Buscar aluno:" required />
              <CCol md={20}>
                <CButton onClick={e => callSearch(e)} className={classes.cadastrarButton} color="primary" disabled={!isCPFValid} type="submit">Buscar</CButton>
              </CCol>
            </CCol>
          </CForm>
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      R${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mês
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} onClick={() => nextStep(tier.plano)} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}