import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CButton } from '@coreui/react';

import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Busca() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const classes = useStyles();
    const [aluno, setAluno] = useState(state.aluno)
    const [submit, setSubmit] = useState(false)

    const voltar = () => {
        navigate("/")
    }

    const callSearch = async (e, month) => {
        e.preventDefault();
        setSubmit(true)
        switch (state.aluno.aluno.pagamento) {
            case 'pix':
                await api.post(`/payment/pix`, {
                    "name": state.aluno.aluno.nome,
                    "email": state.aluno.aluno.email,
                    "plano": state.aluno.aluno.plano,
                    "cpf": "70019443439",
                    "due": month,
                    "ddd": "84",
                    "number": state.aluno.aluno.celular,
                }).then(rs => {
                    window.open(rs.data.qrcodeurl, '_blank')
                }).catch(error => console.log(error));
                break;
            case "boleto":
                await api.post(`/payment/boleto`, {
                    "name": state.aluno.aluno.nome,
                    "email": state.aluno.aluno.email,
                    "plano": state.aluno.aluno.plano,
                    "cpf": "70019443439",
                    "due": month,
                }).then(rs => {
                    window.open(rs.data.url, '_blank')
                }).catch(error => console.log(error));
                break;
            default:
                alert("construcition")
                break;
        }

    }
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>

                <Toolbar className={classes.toolbar}>
                    <Button onClick={() => voltar()} color="primary" variant="outlined" className={classes.link}>
                        Voltar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
                    Gadelha Team
                </Typography>
            </Container>
            <Container maxWidth="md" component="main">
                <MDBTable>
                    <MDBTableHead className={classes.tabela}>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>CPF</th>
                            <th scope='col'>Plano</th>
                            <th scope='col'>Modo</th>
                            <th scope='col'>Data</th>
                            <th scope='col'>Pagamento</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {aluno ? aluno.months.map((month, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{aluno.aluno.cpf}</td>
                                        <td>{aluno.aluno.plano}</td>
                                        <td>{aluno.aluno.pagamento ? aluno.aluno.pagamento : 'Dinheiro'}</td>
                                        <td>{month}</td>
                                        <td>
                                            <CButton onClick={e => callSearch(e, month)} className={classes.cadastrarButton} color="primary" type="submit">Pagar</CButton>

                                        </td>
                                    </tr>
                                </>
                            )
                        }) : ''}
                    </MDBTableBody>
                </MDBTable>
            </Container>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
