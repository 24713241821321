import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { cilCloudDownload } from '@coreui/icons';
import { useNavigate } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CSVLink } from 'react-csv';
import CIcon from '@coreui/icons-react';
import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Painel() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [alunos, setAlunos] = useState(false)
    const [dados, setDados] = useState(false)

    const pullAlunos = async () => {
        await api.get("/alunos/obterAlunos").then(rs => {
            setAlunos(rs.data)
        }).catch(console.log("Erro ao puxar alunos!"));
    }

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('auth');

        navigate("/")
    }

    const pullRelatorio = async () => {
        await api.get("/alunos/obterRelatorio").then(rs => {
            setDados(rs.data)
        }).catch(console.log("Erro ao puxar relatorio!"));
    }

    useEffect(() => {
        pullAlunos();
        pullRelatorio();
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Button onClick={() => logOut()} color="primary" variant="outlined" className={classes.link}>
                        Log Out
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
                    Gadelha Team
                </Typography>
            </Container>
            <Container maxWidth="md" component="main">
                <MDBTable>
                    <MDBTableHead className={classes.tabela}>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>E-mail</th>
                            <th scope='col'>CPF</th>
                            <th scope='col'>Plano</th>
                            <th scope='col'>Pagamento</th>
                            <th scope='col'>
                                <CSVLink data={dados ? dados : ""} filename={'dados.csv'}>
                                    <Button>
                                        <CIcon className={classes.icone} icon={cilCloudDownload} title="Download file" />
                                    </Button>
                                </CSVLink>
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {alunos ? alunos.map((aluno, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{aluno.email}</td>
                                        <td>{aluno.cpf}</td>
                                        <td>{aluno.plano}</td>
                                        <td>{aluno.pagamento ? aluno.pagamento : 'Dinheiro'}</td>
                                        <td>
                                        </td>
                                    </tr>
                                </>
                            )
                        }) : ''}
                    </MDBTableBody>
                </MDBTable>
            </Container>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
