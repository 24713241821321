import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Cadastro from './pages/cadastro';
import Login from './pages/login';
import Painel from './pages/painel';
import Busca from './pages/busca';
const hello = process.env.REACT_APP_PUBLIC_ENDPOINT
function App() {
  console.log("+=====", process.env.REACT_APP_PUBLIC_ENDPOINT)
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="cadastro" element={<Cadastro />} />
            <Route path="login" element={<Login />} />
            <Route path="busca" element={<Busca />} />
            <Route path="dashboard" element={
              localStorage.getItem("token") !== null ? (
                <Painel />
              ) : (
                <Navigate to="/login" />
              )
            } />
          </Routes>
        </Router>

    </div >
  );
}

export default App;
